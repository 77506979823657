import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { BiMenu, BiX } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Logo from "../assets/logo.webp";

const lngs = [
  { code: "pl", native: "Polski" },
  { code: "en", native: "English" },
  { code: "de", native: "Deutsch" },
];

export default function Navbar() {
  const [active, setActive] = useState("");

  const { i18n, t } = useTranslation();

  const handleTrans = (code) => {
    i18n.changeLanguage(code);
  };

  const navToggle = () => {
    if (active === "") {
      setActive("navbar_active");
    } else setActive("");
  };

  return (
    <nav className="navbar">
      <div className="navbar_logo">
        <Link to={"/"}>
          <img src={Logo} alt="logo" className="logo" />
        </Link>
      </div>
      <div className="navbar_list">
        <div className="navbar_button" onClick={navToggle}>
          {active ? <BiX size="2rem" /> : <BiMenu size="2rem" />}
        </div>
        <ul className={active}>
          <li onClick={navToggle}>
            <Link className="navbar_a" to={"/o-nas"}>
              {t("nav_about_us")}
            </Link>
          </li>
          <li onClick={navToggle}>
            <Link className="navbar_a" to={"/oferta"}>
              {t("nav_offer")}
            </Link>
          </li>
          <li onClick={navToggle}>
            <Link className="navbar_a" to={"/projekty"}>
              {t("nav_projects")}
            </Link>
          </li>
          <li onClick={navToggle}>
            <Link className="navbar_a" to={"/praca"}>
              {t("nav_jobs")}
            </Link>
          </li>
          <li onClick={navToggle}>
            <Link className="navbar_a" to={"/kontakt"}>
              {t("nav_contact")}
            </Link>
          </li>
          <li onClick={navToggle}>
            <div className="navbar_language">
              {lngs.map((lang) => (
                <button
                  className={i18n.language === lang.code ? "lang_active" : ""}
                  onClick={() => handleTrans(lang.code)}
                >
                  {lang.code.toUpperCase()}
                </button>
              ))}
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
