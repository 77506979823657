import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDXW71xew4VpmdAwn5SHC2tBeWsdqNjiKk",
  authDomain: "almont-d855e.firebaseapp.com",
  projectId: "almont-d855e",
  storageBucket: "almont-d855e.appspot.com",
  messagingSenderId: "908962794048",
  appId: "1:908962794048:web:10a81c430be29a13beada5",
};

firebase.initializeApp(firebaseConfig);

const projectFirestore = firebase.firestore();
// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore as db, timestamp };
