import React from "react";
import "./Oferta.css";
import Heading from "../components/Heading";
import Section from "../components/Section";

import { useTranslation } from "react-i18next";

export default function Oferta() {
  const { t } = useTranslation();

  return (
    <div className="oferta">
      <Heading
        text={t("oferta_heading").toUpperCase()}
        img={
          "https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2Fcsm_2017-325-1_b9e9de804d.jpg.webp?alt=media&token=8fa53ce8-586a-44bb-90d9-039a34dd75b3"
        }
      />
      <div className="oferta_sections">
        <section>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2Fdorint_hotel_b.webp?alt=media&token=717df998-f110-4cf3-963f-92da201375ac"
            alt="obraz poglądowy"
          />

          <div className="oferta_section_content">
            <div className="oferta_section_content_container">
              <div className="oferta_section_content_top">
                <p>{t("oferta_section_heading1")}</p>
              </div>

              <div className="oferta_section_content_bottom">
                <p>{t("oferta_section_content1")}</p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="oferta_section_content">
            <div className="oferta_section_content_container">
              <div className="oferta_section_content_top">
                <p>{t("oferta_section_heading2")}</p>
              </div>

              <div className="oferta_section_content_bottom">
                <p>{t("oferta_section_content2")}</p>
              </div>
            </div>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2FWhatsApp%20Image%202022-09-28%20at%2010.15.04.jpeg?alt=media&token=49c8073c-2c5e-4933-8f28-0faf6d7ab37f"
            alt="obraz poglądowy"
          />
        </section>
      </div>
    </div>
  );
}
