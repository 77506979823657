import React from "react";
import Heading from "../components/Heading";
import Section from "../components/Section";
import "./Onas.css";
import { GrUserWorker } from "react-icons/gr";
import { FaHandshake } from "react-icons/fa";
import { ImEarth } from "react-icons/im";

import { useTranslation } from "react-i18next";

export default function Onas() {
  const { t } = useTranslation();

  return (
    <div className="o-nas">
      <Heading
        text={t("onas_heading").toUpperCase()}
        img={
          "https://firebasestorage.googleapis.com/v0/b/almont-d855e.appspot.com/o/assets%2Fgrand-tower.webp?alt=media&token=e01b7a33-7178-4f7d-bc9e-7fb517474ca9"
        }
      />
      <Section
        title={t("onas_section_heading")}
        textLeft={t("onas_section_left")}
        textRight={t("onas_section_right")}
      />
    </div>
  );
}
