import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer_left">
        <p>&copy; Almont Plus / All rights reserved</p>
      </div>
      <div className="footer_right">
        {/* //TODO:link telefon i mail */}
        <p>502 363 508 almont@almont.com.pl</p>
      </div>
    </footer>
  );
}
